import {KMDConnectionParams, NodeConnectionParams} from "../packages/core-sdk/types";
import {REACT_APP_NETWORK} from "../env";
import {NETWORKS} from "../packages/core-sdk/constants";

export const supportSettings = REACT_APP_NETWORK === '';

export function getNodeConfig(): NodeConnectionParams {
    const availableNodes = getNodes();

    const network = REACT_APP_NETWORK;
    if (network) {
        if (network === NETWORKS.SANDBOX) {
            return availableNodes[0];
        }
        if (network === NETWORKS.TESTNET) {
            return availableNodes[1];
        }
        if (network === NETWORKS.MAINNET) {
            return availableNodes[2];
        }
    }

    const defaultNode = availableNodes[2];

    return {
        ...defaultNode,
        algod: {
            url: localStorage.getItem('algodUrl') || defaultNode.algod.url,
            port: localStorage.getItem('algodPort') || defaultNode.algod.port,
            token: localStorage.getItem('algodToken') || defaultNode.algod.token,
        },
        indexer: {
            url: localStorage.getItem('indexerUrl') || defaultNode.indexer.url,
            port: localStorage.getItem('indexerPort') || defaultNode.indexer.port,
            token: localStorage.getItem('indexerToken') || defaultNode.indexer.token,
        }
    }
}

export function getKMDConfig(): KMDConnectionParams {
    const defaultKMDConfig: KMDConnectionParams = {
        url: 'http://localhost',
        token: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
        port: '4002'
    };

    return {
        url: localStorage.getItem('kmdUrl') || defaultKMDConfig.url,
        port: localStorage.getItem('kmdPort') || defaultKMDConfig.port,
        token: localStorage.getItem('kmdToken') || defaultKMDConfig.token,
    }
}

export function getNodes(): NodeConnectionParams[] {
    return [{
        id: 'sandbox',
        label: 'Sandbox',
        algod: {
            url: 'http://localhost',
            port: '4001',
            token: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa'
        },
        indexer: {
            url: 'http://localhost',
            port: '8980',
            token: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa'
        }
    },
        {
            id: 'algonode_testnet',
            label: 'Algonode testnet',
            algod: {
                url: 'http://45.76.169.36',
                port: '8081',
                token: 'ac30b22a1a2914e0049e8ae56728aab9dff50bf0b9e8e2010c34f4feb6205562',
            },
            indexer: {
                url: 'http://140.82.17.3',
                port: '8980',
                token: '71a7f130d36fd0be01fca140a91eba33995c46ebe0715740bcfd068c4c4af9b7',
            }
        },
        {
            id: 'algonode_mainnet',
            label: 'Algonode mainnet',
            algod: {
                url: 'http://45.76.169.36',
                port: '8081',
                token: 'ac30b22a1a2914e0049e8ae56728aab9dff50bf0b9e8e2010c34f4feb6205562',
            },
            indexer: {
                url: 'http://140.82.17.3',
                port: '8980',
                token: '71a7f130d36fd0be01fca140a91eba33995c46ebe0715740bcfd068c4c4af9b7',
            }
        },
        {
            id: 'algonode_betanet',
            label: 'Algonode betanet',
            algod: {
                url: 'http://45.76.169.36',
                port: '8081',
                token: 'ac30b22a1a2914e0049e8ae56728aab9dff50bf0b9e8e2010c34f4feb6205562',
            },
            indexer: {
                url: 'http://140.82.17.3',
                port: '8980',
                token: '71a7f130d36fd0be01fca140a91eba33995c46ebe0715740bcfd068c4c4af9b7',
            }
        },
        {
            id: 'algoexplorer_testnet',
            label: 'AlgoExplorer testnet',
            algod: {
                url: 'http://45.76.169.36',
                port: '8081',
                token: 'ac30b22a1a2914e0049e8ae56728aab9dff50bf0b9e8e2010c34f4feb6205562',
            },
            indexer: {
                url: 'http://140.82.17.3',
                port: '8980',
                token: '71a7f130d36fd0be01fca140a91eba33995c46ebe0715740bcfd068c4c4af9b7',
            }
        },
        {
            id: 'algoexplorer_mainnet',
            label: 'AlgoExplorer mainnet',
            algod: {
                url: 'http://45.76.169.36',
                port: '8081',
                token: 'ac30b22a1a2914e0049e8ae56728aab9dff50bf0b9e8e2010c34f4feb6205562',
            },
            indexer: {
                url: 'http://140.82.17.3',
                port: '8980',
                token: '71a7f130d36fd0be01fca140a91eba33995c46ebe0715740bcfd068c4c4af9b7',
            }
        },
        {
            id: 'algoexplorer_betanet',
            label: 'AlgoExplorer betanet',
            algod: {
                url: 'http://45.76.169.36',
                port: '8081',
                token: 'ac30b22a1a2914e0049e8ae56728aab9dff50bf0b9e8e2010c34f4feb6205562',
            },
            indexer: {
                url: 'http://140.82.17.3',
                port: '8980',
                token: '71a7f130d36fd0be01fca140a91eba33995c46ebe0715740bcfd068c4c4af9b7',
            }
        }];
}


