import React from "react";

function LinkToBlock({id, name = ''}): JSX.Element {
    if (!name) {
        name = id;
    }

    return <a className="text-[#7e7e7e] text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] underline-offset-[6px] decoration-[#a7a7a7] decoration-dotted underline" href={"/explorer/block/" + id}>{name}</a>;
}

export default LinkToBlock;