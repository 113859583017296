import React, { useEffect, useRef } from 'react';

const Sparkline = ({ sparklineData }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const data = sparklineData.slice(0, -1); // Exclude the last value
        const minValue = Math.min(...data);
        const maxValue = Math.max(...data);
        const range = maxValue - minValue;

        ctx.beginPath();
        ctx.moveTo(0, canvas.height - ((data[0] - minValue) / range * canvas.height));

        const stepX = canvas.width / (data.length - 1);
        data.forEach((value, index) => {
            const x = stepX * index;
            const y = canvas.height - ((value - minValue) / range * canvas.height);
            ctx.lineTo(x, y);
        });

        ctx.strokeStyle = data[0]< data[data.length -1]?'green' : 'red';
        ctx.lineWidth = 2;
        ctx.stroke();
    }, [sparklineData]);

    return <canvas ref={canvasRef} width={100} height={20} />;
};

export default Sparkline;

