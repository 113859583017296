import React, {FC, PropsWithChildren} from 'react'

interface Props {
    title?:string
    link?: string
}
const BalanceCard:FC<PropsWithChildren<Props>> = ({children, title, link}) => {
    return (
        <div className="w-full h-[142px] max-lg:h-auto max-lg:p-[12px_20px] max-lg:gap-3 p-6 bg-white rounded-2xl flex flex-col gap-8 shadow-[0_1px_2px_0_rgba(0,0,0,0.11)] dark:bg-[#ffffff0a] ">
            {
                link ? <a href={link}
                          className=" text-[#7e7e7e] text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] underline-offset-[6px] decoration-[#a7a7a7] decoration-dotted underline">{title}</a> :
                    <p
                       className="text-[#7e7e7e] text-[13px] font-medium leading-[1.85em] -tracking-[0.04px]">{title}</p>
            }
            <div className="h-10">
                {children}
            </div>

        </div>
    )
}

export default BalanceCard;