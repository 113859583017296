import React from "react";
import {ellipseString, sliceString} from "../../../../../packages/core-sdk/utils";

function LinkToAccount({address, strip = 0, type = "center"}): JSX.Element {
    return(
        type === "center" ?
            <a className={"text-[#7e7e7e] text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] underline-offset-[6px] decoration-[#a7a7a7] decoration-dotted underline"} href={"/explorer/account/" + address}>{sliceString(address, strip)}</a>:
            <a className={"text-[#7e7e7e] text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] underline-offset-[6px] decoration-[#a7a7a7] decoration-dotted underline"} href={"/explorer/account/" + address}>{strip ? ellipseString(address, strip) : address}</a>
    )
}

export default LinkToAccount;