import { useState, useEffect } from 'react';
import './Home.scss';
import Search from '../Search/Search';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

import BalanceCard from '../../../cards/Balance';
import GainerList from '../../../lists/Gainer';
import bgLight from '../../../../assets/images/bg-light.png';
import copy from '../../../../assets/icons/copy.svg';

function Home(): JSX.Element {
    const [algoData, setAlgodata] = useState(null);
    const liveData = useSelector((state: RootState) => state.liveData);
    const {blocks} = liveData;
    const fetchPrice = async () => {
        try {
            const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=algorand&vs_currencies=usd&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true&precision=3');
            const result = await response.json();
            setAlgodata(result);
        } catch (error) {
            console.error('Error fetching price data:', error);
        }
    };
    useEffect(() => {
        fetchPrice();
        const interval = setInterval(() => {
            fetchPrice();
        }, 60000); 

        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <div className="max-lg:p-[40px_12px_80px] max-w-[1128px] p-[52px_40px_0] my-0 mx-auto">
                {/* <div className="max-lg:bg-[50%] absolute top-0 left-0 -z-[1] w-full h-full bg-no-repeat bg-cover"
                     style={{backgroundImage: `url(${bgLight})`}}></div> */}
                {/* <h2 className="text-4xl font-normal leading-[1.2em] -tracking-[0.79px] dark:text-white">Xana Explorer</h2> */}
                <div className="m-[30px_auto_0]">
                    <Search/>
                </div>

                <div className="flex gap-4 mt-10 max-lg:flex-wrap max-lg:gap-3">
                    <BalanceCard title="Xana Price">
                        {algoData && <div className="flex items-center gap-[12px]">
                            <div className="text-2xl font-normal leading-[1.5em] -tracking-[0.47px] dark:text-white">$0.01
                                {/* ${parseFloat(algoData.algorand.usd).toFixed(3)} */} 
                            </div>
                          {/*  <div
                                className={`text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] p-[4px_8px] rounded-2xl ${algoData.algorand.usd_24h_change < 0 ? 'bg-[#00ff00] text-[#f65549]' : 'bg-[#00b19e1a] text-[#30967d]'}`}>
                               {algoData.algorand.usd_24h_change > 0 ? "+" : ""}{algoData.algorand.usd_24h_change.toFixed(2)}% 
                            </div> */}
                        </div>}
                    </BalanceCard>
                    <BalanceCard title="Market Cap">
                        {algoData && <div className="flex items-center gap-[12px]">
                            <div className="text-2xl font-normal leading-[1.5em] -tracking-[0.47px] dark:text-white">$100,000,000
                           {/* ${parseInt(algoData.algorand.usd_market_cap).toLocaleString()} */}
                            </div>
                        </div>}
                    </BalanceCard>
                    <BalanceCard title="Transactions" link="/explorer/transactions">
                        {blocks.length && <div className="flex justify-between items-end">
                            <div className="text-2xl font-normal leading-[1.5em] -tracking-[0.47px] dark:text-white">
                                {blocks[0].transactions.length} TPS
                            </div>
                            <div className="flex gap-1">
                                <p className="text-[#7e7e7e] text-[13px] font-medium leading-[1.85em] -tracking-[0.04px]">{(blocks[0]['txn-counter'] / 1000000000).toFixed(2) }B</p>
                                <span
                                    className="text-[#7e7e7e] text-[13px] font-medium leading-[1.85em] -tracking-[0.04px]">(Total)</span>
                            </div>
                        </div>}
                    </BalanceCard>
                    <BalanceCard title="Last Block" link={`/explorer/block/${blocks[0]?.round || ''}`}>
                        {blocks.length && <div className="flex items-center gap-3">
                            <div className="text-2xl font-normal leading-[1.5em] -tracking-[0.47px] dark:text-white">
                                {blocks[0]?.round}
                            </div>
                            <img src={copy} className="cursor-pointer" onClick={()=> navigator.clipboard.writeText(blocks[0].round.toString())} alt="icon"/>
                        </div>}
                    </BalanceCard>
                </div>

                <div className="flex flex-col mt-20 max-lg:mt-[72px]">
                    <h4 className="text-2xl font-normal leading-[1.5em] -tracking-[0.47px] dark:text-white">Top Tokens</h4>
                    <div className="mt-5">
                        <div
                            className="grid gap-2 p-[12px_24px] text-[#a7a7a7] text-[13px] font-medium leading-[1.85em] -tracking-[0.04px] max-lg:hidden"
                            style={{"gridTemplateColumns": "300px 1fr 1fr 1fr 1fr 100px"}}>
                            <span>Asset</span>
                            <span>Price</span>
                            <span>Gain</span>
                            <span>Market Cap</span>
                            <span>Volume</span>
                            <span></span>
                        </div>
                        <GainerList className="mb-1" coinId="HIVsRcGKkPFtW"/>
                        <GainerList className="mb-1" coinId='aKzUVe4Hh_CON'/>
                        <GainerList coinId='MoTuySvg7'/>
                    </div>
                    {/* <p className="flex justify-center  gap-1 mt-[28px] text-[#a7a7a7] ">
                        ASA price information powered by
                        <img src={vestigeLogo} alt="icon"/>
                    </p> */}
                </div>
            </div>
        </div>
    )
}
export default Home;
