import './Header.scss';
import React, { useState, useEffect } from "react";
import logoLight from '../../../../assets/logos/logo-light.webp';
import logoDark from '../../../../assets/logos/logo-dark.webp';
// import lightIcon from "../../../../assets/icons/light-icon.svg";
// import darkIcon from "../../../../assets/icons/dark-icon.svg";

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { toggleTheme } from '../../../../redux/theme.slice';


function Header(): JSX.Element {

    const [scrollPosition, setScrollPosition] = useState(0);

    const dispatch = useDispatch();
    const isDarkMode = useSelector((state: RootState) => state.theme.dark);

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);
    const onToggleTheme = () => {
        dispatch(toggleTheme());
    };

    return (<div >
        <div style={{ transition: "background-color .1s ease-in-out" }} className={`header-container max-lg:h-auto max-lg:static h-[90px] w-full sticky top-0 z-10 ${scrollPosition > 45 && 'bg-[#ffffff] dark:bg-[#141414]'}`}>
            <div
                className="max-w-[1048px] max-lg:max-w-full max-lg:p-[32px_12px_0] flex items-center justify-between gap-[10px] h-full p-[32px_0_16px] mx-auto">
                <div className="flex items-center gap-4 max-lg:justify-between max-lg:w-full">
                    <div className="flex items-center gap-2">
                        <a href="/" className="h-[30px] w-[80px] p-0 bg-no-repeat bg-contain border-none"
                            style={{ backgroundImage: `url(${isDarkMode? logoDark: logoLight})` }}></a>
                        <h2 className="text-[13px] text-[#7e7e7e] font-medium leading-[1.85em] -tracking-[0.04px] p-[2px_0_2px_8px] border-l border-[rgba(0, 23, 22, .08)] ">Explorer</h2>
                    </div>
                </div>
                <div className="flex items-center gap-4">
                    {/* <button
                        onClick={onToggleTheme}
                        style={{transition: "background-color .1s ease-in-out"}}
                        className="max-lg:p-1 flex items-center justify-center p-2 shadow-[0_1px_2px_0_rgba(0,0,0,0.11)] bg-[#ffffff] rounded-[8px] hover:bg-[#00171614] dark:bg-[#ffffff0a] dark:hover:bg-[#ffffff14]">
                        <div className="max-lg:w-[20px] max-lg:h-[20px] bg-cover w-[24px] h-[24px]"
                             style={{backgroundImage: `url(${isDarkMode ? darkIcon : lightIcon})`}}></div>
                    </button> */}
                </div>
            </div>
        </div>
    </div>);
}

export default Header;
