import {theme} from "../index";


// const themes = useTheme();
import {shadedClr} from "../../utils/common";

export const dataGridCellConfig = {
    sortable: false,
    editable: false,
    flex: 1,
    disableColumnMenu: true,
};

export const dataGridStyles = {
    // '.MuiDataGrid-columnSeparator': {
    //     display: 'none',
    // },
    // '.MuiDataGrid-row:nth-of-type(odd)': {
    //     backgroundColor: theme.palette.common.white
    // },
    // '.MuiDataGrid-row:nth-of-type(even)': {
    //     backgroundColor: shadedClr
    // },
    // '.MuiDataGrid-row:hover': {
    //     backgroundColor: shadedClr + ' !important'
    // },
    // '.MuiDataGrid-cell:focus': {
    //     outline: "none"
    // },
    // '.MuiDataGrid-columnHeader:focus': {
    //     outline: "none"
    // },
    '.MuiDataGrid-main': {
      background:  theme.palette.primary.contrastText
    },
    '.MuiDataGrid-columnSeparator': {
      display: 'none'
    },
    '.MuiDataGrid-columnHeader': {
        background: '#f5f5f5',
        color: '#a7a7a7'
    },
    '.MuiDataGrid-columnHeaders': {
        borderRadius: '0 !important'
    }
};

export const dataGridStylesDark = {
    '.MuiDataGrid-main': {
        background:  '#ffffff0a'
    },
    '.MuiDataGrid-columnSeparator': {
        display: 'none'
    },
    '.MuiDataGrid-columnHeader': {
        background: '#141414',
        color: '#a7a7a7'
    },
    '.MuiDataGrid-columnHeaders': {
        borderRadius: '0 !important'
    }
};

export const dataGridStylesBlackHeader = {
    ...dataGridStyles,
    '.MuiDataGrid-columnHeader': {
        background: theme.palette.common.black,
        color: theme.palette.common.white
    }
};