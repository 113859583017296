import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IAppState {
    dark?: boolean;
}

const initialState: IAppState = {
    dark: false
};

export const themeSlice = createSlice({
    name: 'app',
    initialState: initialState as IAppState,
    reducers: {
        toggleTheme(state) {
            state.dark = !state.dark
            localStorage.setItem('theme', JSON.stringify(state.dark));
        },
        setAppState(state, action: PayloadAction<IAppState>) {
            return action.payload
        },
        resetAppState() {
            return initialState
        }
    }
})

export const {toggleTheme, setAppState, resetAppState } = themeSlice.actions