import React from 'react'

import { useSelector } from "react-redux";
import { RootState } from '../../../../redux/store'

const AppFooter = () => {
    const isDarkMode = useSelector((state: RootState) => state.theme.dark);

    return (
        <div className="mt-[28px] max-w-[1048px] mx-auto">
            <div className="p-[60px_0_45px] max-lg:p-[46px_0_30px] border-solid border-y-2 border-y-[#0017160a] dark:border-y-[#212121]">
                <h5 className="text-center text-[15px] font-normal leading-[1.6em] -tracking-[0.13px] dark:text-white">
                © 2024 Xana Foundation, All rights reserved</h5>
            </div>
        </div>
    )
}

export default AppFooter;